.conti {
    background-color: #ac7f99;
  }

  .logo{
    height: 6vh;
  }

  .it1{
    height:clamp(100px,70vh,600px);
    background-image: url("../public/woman.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 69%;
    background-blend-mode: darken;
    background-color: rgba(0, 0, 0, 0.3);
  }
  .it2{
    height:clamp(200px,70vh,600px);
    background-image: url("../public/micro.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 69%;
    background-blend-mode: darken;
    background-color: rgba(0, 0, 0, 0.3);
  }
  .it3{
    height:clamp(100px,70vh,600px);
    background-image: url("../public/diet.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: darken;
    background-position: 69%;
    background-color: rgba(0, 0, 0, 0.3);
  }
  
  .navbar-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: clamp(20px, 2vh, 30px);
  }
  
  .tit {
    font-family: sans-serif;
    color: #717171;
    font-weight: 600 !important;
  }

  .tit1{
    font-family: sans-serif;
    color: #717171;
    font-weight: 600 !important;
    background-color: transparent !important;
  }
  
  
  .navbar-button {
    background-color: transparent;
    color: rgb(197, 127, 138) !important;
    border: none;
    font-family: sans-serif;
    transition: background-color 0.3s ease, color 0.3s ease;
  }

  .custom-dropdown .nav-link {
    color:  rgb(197, 127, 138) !important

  }

  .custom-dropdown .nav-link:hover{
    color:  rgb(226, 182, 100) !important;
  }

  .carousel-content{
    font-family: 'Roboto', sans-serif;
    display: block;
    }

  .wrappy{
    height:100vh;
  }

  .headd{
    font-weight: 600;
    color: rgb(158, 158, 158);
  }

  .lan{
    margin-top: 11rem !important;
  }

  .bbbb{
    background-color: rgb(250, 211, 217);
  }


  .head{
    font-weight:bold;
    color: white;
  }

  .pin{
    background-color: rgb(206, 142, 152);
    height: 99vh;
  }

  .desc{
    color: white;
    font-size: 0.49em !important;
  }
  
  .navbar-button:hover {
    color: rgb(226, 182, 100) !important;
  }

  
  .image-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image-container img {
    max-width: 100%;
    max-height: 100%;
  }

  .sub{
    font-family: 'Roboto', sans-serif;
    color:rgb(175, 142, 98);
    font-weight: 500 !important;
  }



.pink{
  background-color: rgb(206, 142, 152);
  background-blend-mode: darken;
}


.but {
  background-color: rgb(206, 142, 152);
  color: white;
  border: none;
  border-radius: 40px;
  padding: 10px 20px;
  transition: background-color 0.3s, color 0.3s;
  font-size: 1.1rem;
}

.butservice {
  background-color: rgb(131, 130, 130);
  color: white;
  border: none;
  border-radius: 40px;
  padding: 10px 20px;
  transition: background-color 0.3s, color 0.3s;
  font-size: 1.5rem;
}

.butservice:hover {
  background-color: white;
  color: rgb(224, 145, 158);
}


.navbar-toggler-icon {
  background-color: rgb(206, 142, 152) !important;
  border-radius: 15% !important;
}

.title2{
  font-weight: 600 !important;
  color: rgb(131, 130, 130);
}

.hidden{
  opacity: 0;
  filter: blur(5px);
  transform: translateX(-100%);
  transition: all 1s;
}

.show{
  opacity: 1;
  filter: blur(0);
  transform: translateX(0);
}


.but2 {
  background-color: rgb(131, 130, 130);
  color: rgb(255, 255, 255);
  border: none;
  border-radius: 40px;
  padding: 10px 20px;
  transition: background-color 0.3s, color 0.3s;
}

.but:hover {
  background-color: white;
  color: rgb(224, 145, 158);
}

.but2:hover {
  background-color: white;
  color: rgb(224, 145, 158);
}

.image-hei{
  height:100%;
}

.dent-title{
  color: white;
  font-weight: 700 !important;
}

.dent-desc{
  color: white;
}


section {
  padding: 60px 0;
}

section .section-title {
  text-align: center;
  color: #007b5e;
  margin-bottom: 50px;
  text-transform: uppercase;
}
#footer {
  background: rgb(206, 142, 152) !important;
}
#footer h5{
padding-left: 10px;
  border-left: 3px solid #eeeeee;
  padding-bottom: 6px;
  margin-bottom: 20px;
  color:#ffffff;
}
#footer a {
  color: #ffffff;
  text-decoration: none !important;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
#footer ul.social li{
padding: 3px 0;
}
#footer ul.social li a i {
  margin-right: 5px;
font-size:25px;
-webkit-transition: .5s all ease;
-moz-transition: .5s all ease;
transition: .5s all ease;
}
#footer ul.social li:hover a i {
font-size:30px;
margin-top:-10px;
}
#footer ul.social li a,
#footer ul.quick-links li a{
color:#ffffff;
}
#footer ul.social li a:hover{
color:#eeeeee;
}
#footer ul.quick-links li{
padding: 3px 0;
-webkit-transition: .5s all ease;
-moz-transition: .5s all ease;
transition: .5s all ease;
}
#footer ul.quick-links li:hover{
padding: 3px 0;
margin-left:5px;
font-weight:600;
}
#footer ul.quick-links li a i{
margin-right: 5px;
}

@media (max-width:767px){
#footer h5 {
  padding-left: 0;
  border-left: transparent;
  padding-bottom: 0px;
  margin-bottom: 10px;
}
}

.hotline{
  color: #ffffff;
}

.box {
  background-color: rgb(206, 142, 152);
  color: white;
  border: none;
  padding: 10px 20px;
  transition: background-color 0.3s, color 0.3s;
  font-size: 1.5rem;
}
.box2{
  background-color: rgb(129, 126, 126);
  color: white;
  border: none;
  padding: 10px 20px;
  transition: background-color 0.3s, color 0.3s;
  font-size: 1.5rem;
  border-radius: 40px;
}
.box2:hover{
  background-color: white;
  color: rgb(206, 142, 152); ;
}


.box:hover {
  background-color: white;
  color: rgb(224, 145, 158);
}

.color{
  background-color: rgb(206, 142, 152);
}

.why{
  color: white;
  font-weight: 400 !important;
}

.line{
  border: none;
  border-radius: 60px;
  border-top: 30px solid rgb(170, 69, 84) !important; 
}

.about{
  color: #ffffff;
  font-size: 4.5rem;
}


.wrapper{margin:10vh}


.login-box {
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 600px;
  margin: auto;
}

.login-box h2 {
  text-align: center;
  margin-bottom: 20px;
}

.login-box .form-group {
  margin-bottom: 20px;
}

.login-box .form-control {
  border-radius: 10px;
  height: 50px;
  font-size: 16px;
}

.login-box .btn-lg {
  border-radius: 10px;
  height: 50px;
  font-size: 18px;
}

.login-box .btn-link {
  font-size: 14px;
}

.ezbot {
  min-width: 200vw !important;
}
.darker{
  background-blend-mode: darken;
  background-color: rgba(0, 0, 0, 0.3);
}



@media (min-width: 992px) and (max-width: 1199px) {

  .service{
    height: 380px;
    width: 380px;
  }

  .serviceTitle{
    font-size: 2.5rem !important;
    font-weight: 400 !important;
  }

  .paragraph{
    font-size: 1rem;
    line-height: 1.9rem !important;
 }


  .space {
    margin-left: 2.6rem;
  }

  .righ{
    margin-left: 8rem;
  }
}


@media (max-width: 576px) {

  .service{
    max-width: 100%;
    height: auto;
  }
}

@media (min-width: 576px) and (max-width: 768px) {

  .service{
    height: 220px;
    width: 220px;
  }

  .serviceTitle{
    font-size: 1.9rem !important;
    font-weight: 400 !important;
  }

  .paragraph{
    font-size: 0.8rem;
    line-height: 1.3rem !important;
 }
}


@media (min-width: 768px) and (max-width: 991px) {

  .service{
    height: 300px;
    width: 300px;
  }

  .serviceTitle{
    font-size: 2.2rem !important;
    font-weight: 400 !important;
  }

  .paragraph{
    font-size: 1rem;
    line-height: 1.9rem !important;
 }

  .space{
    margin-left :2rem;
  }

  .righ{
    margin-left: 2.5rem;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {


  .service{
    height: 500px;
    width: 500px;
  }

  .paragraph{
    font-size: 1.2rem;
 }

  .space{
    margin-left :3rem;
  }

  .righ{
    margin-left:16rem;
  }
  .head{
    font-size: 60px;
  }
}


@media (min-width: 1400px) and  (max-width: 1499px){


  
  .marginTop{
    margin-top: 0rem !important;
  }
  .service{
    height: 500px;
    width: 500px;
  }

  .paragraph{
    font-size: 1.3rem;
 }

  .righ{
    margin-left:20rem;
  }

  .space{
    margin-left :4rem;
  }
  .head{
    font-size: 60px;
  }
}

@media (min-width: 1500px){

  .marginTop{
    margin-top: 1.8rem !important;
  }

    .service{
      height: 570px;
      width: 570px;
    }

    .paragraph{
       font-size: 1.5rem;
    }

  .righ{
    margin-left:25rem;
  }

  .space{
    margin-left :5rem;
  }
  .head{
    font-size: 60px;
  }
}

.shadow{
  box-shadow: 50px 40px 90px rgba(0, 0, 0, 0.9) !important;
}

.circle {
  border-radius: 9%;
}

.cont {
  position: relative;
  width: 100%;
  background-color: #fff;
  min-height: 100vh;
  overflow: hidden;
}

.forms-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.signin-signup {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 75%;
  width: 50%;
  transition: 1s 0.7s ease-in-out;
  display: grid;
  grid-template-columns: 1fr;
  z-index: 5;
}

form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0rem 5rem;
  transition: all 0.2s 0.7s;
  overflow: hidden;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

form.sign-up-form {
  opacity: 0;
  z-index: 1;
}

form.sign-in-form {
  z-index: 2;
}

.title {
  color: #444;
}

.input-field {
  max-width: 380px;
  width: 100%;
  background-color: #f0f0f0;
  margin: 5px 0;
  height: 55px;
  border-radius: 55px;
  display: grid;
  grid-template-columns: 15% 85%;
  padding: 0 0.4rem;
  position: relative;
}

.input-field i {
  text-align: center;
  line-height: 55px;
  color: #acacac;
  transition: 0.5s;
  font-size: 1.1rem;
}

.input-field input {
  background: none;
  outline: none;
  border: none;
  line-height: 1;
  font-weight: 600;
  font-size: 1.1rem;
  color: #333;
}

.input-field input::placeholder {
  color: #aaa;
  font-weight: 500;
}



.bot {
  width: 150px;
  background-color: rgb(206, 142, 152);
  border: none;
  outline: none;
  height: 49px;
  border-radius: 49px;
  color: #fff;
  font-weight: 600;
  margin: 10px 0;
  cursor: pointer;
  transition: 0.5s;
}

.bot:hover {
  background-color: #ffffff;
  color: rgb(206, 142, 152) ;
}

.bot2{
  width: 150px;
  background-color: rgb(145, 145, 145);
  border: none;
  outline: none;
  height: 49px;
  border-radius: 49px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  margin: 10px 0;
  cursor: pointer;
  transition: 0.5s;
}

.bot2:hover {
  background-color: #ffffff;
  color: rgb(206, 142, 152) ;
}

.panels-container {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
} 


.cont:before {
  content: "";
  position: absolute;
  height: 2000px;
  width: 2000px;
  top: -10%;
  right: 48%;
  transform: translateY(-50%);
  background-image: linear-gradient(-45deg, rgb(206, 142, 152) 0%, rgb(206, 142, 152) 100%);
  transition: 1.4s ease-in-out;
  border-radius: 50%;
  z-index: 6;
}

.image {
  width: 100%;
  transition: transform 1.1s ease-in-out;
  transition-delay: 0.4s;
}

.panel {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  text-align: center;
  z-index: 6;
}

.left-panel {
  pointer-events: all;
  padding: 3rem 17% 2rem 12%;
}

.right-panel {
  pointer-events: none;
  padding: 3rem 12% 2rem 17%;
}

.panel .content {
  color: #fff;
  transition: transform 0.9s ease-in-out;
  transition-delay: 0.6s;
}




.right-panel .image,
.right-panel .content {
  transform: translateX(800px);
}

/* ANIMATION */

.cont.sign-up-mode:before {
  transform: translate(100%, -50%);
  right: 52%;
}

.cont.sign-up-mode .left-panel .image,
.cont.sign-up-mode .left-panel .content {
  transform: translateX(-800px);
}

.cont.sign-up-mode .signin-signup {
  left: 25%;
}

.cont.sign-up-mode form.sign-up-form {
  opacity: 1;
  z-index: 2;
}

.cont.sign-up-mode form.sign-in-form {
  opacity: 0;
  z-index: 1;
}

.cont.sign-up-mode .right-panel .image,
.cont.sign-up-mode .right-panel .content {
  transform: translateX(0%);
}

.cont.sign-up-mode .left-panel {
  pointer-events: none;
}

.cont.sign-up-mode .right-panel {
  pointer-events: all;
}

@media (max-width: 870px) {
  .cont {
    min-height: 800px;
    height: 100vh;
  }
  .signin-signup {
    width: 100%;
    top: 95%;
    transform: translate(-50%, -100%);
    transition: 1s 0.8s ease-in-out;
  }

  .signin-signup,
  .cont.sign-up-mode .signin-signup {
    left: 50%;
  }

  .panels-container {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr 1fr;
  }

  .panel {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 2.5rem 8%;
    grid-column: 1 / 2;
  }

  .right-panel {
    grid-row: 3 / 4;
  }

  .left-panel {
    grid-row: 1 / 2;
  }

  .image {
    width: 200px;
    transition: transform 0.9s ease-in-out;
    transition-delay: 0.6s;
  }

  .panel .content {
    padding-right: 15%;
    transition: transform 0.9s ease-in-out;
    transition-delay: 0.8s;
  }

  .panel h1 {
    font-size: 1.2rem !important;
  }

  .panel .words {
    font-size: 0.7rem !important;
    padding: 0.5rem 0 !important;
  }

  .bot {
    width: 110px;
    height: 35px;
    font-size: 0.7rem;
  }

  .bot2{
    width: 110px;
    height: 35px;
    font-size: 0.7rem;
  }

  .cont:before {
    width: 1500px;
    height: 1500px;
    transform: translateX(-50%);
    left: 30%;
    bottom: 68%;
    right: initial;
    top: initial;
    transition: 2s ease-in-out;
  }

  .cont.sign-up-mode:before {
    transform: translate(-50%, 100%);
    bottom: 32%;
    right: initial;
  }

  .cont.sign-up-mode .left-panel .image,
  .cont.sign-up-mode .left-panel .content {
    transform: translateY(-300px);
  }

  .cont.sign-up-mode .right-panel .image,
  .cont.sign-up-mode .right-panel .content {
    transform: translateY(0px);
  }

  .right-panel .image,
  .right-panel .content {
    transform: translateY(300px);
  }

  .cont.sign-up-mode .signin-signup {
    top: 5%;
    transform: translate(-50%, 0);
  }
}

@media (max-width: 570px) {
  form {
    padding: 0 1.5rem;
  }

  .image {
    display: none;
  }
  .panel .content {
    padding: 0.5rem 1rem;
  }
  .cont {
    padding: 1.5rem;
  }

  .cont:before {
    bottom: 72%;
    left: 50%;
  }

  .cont.sign-up-mode:before {
    bottom: 28%;
    left: 50%;
  }
}

.logotwo{
  height: 8vh !important;
}


.wh{
  color: #fff;
  font-weight: 600 !important;
}

.num{
  color: #fff;
}
.gold{
  color: silver;
}

.error-message{
  color: red;
  font-weight: 600;
  font-size: 1.3rem;
}

.error-message2{
  color: red;
  font-weight: 600;
  font-size: 0.8rem;
}

.green-message{
 color: rgb(65, 199, 83);
 font-weight: 600;
 font-size: 1.3rem;
}

.remain{
  color: #fff;
}


.pricingTable{
  margin-top:30px;
  text-align: center;
  position: relative;
}
.pricingTable .pricing_heading:after{
  content: "";
  width: 36px;
  height: 29.5%;
  background:rgb(206, 142, 152);
  position: absolute;
  border-radius: 5px;
  top: -1px;
  right: 0;
  z-index: 2;
  transform: skewY(45deg) translateY(18px);
  transition: all 0.4s ease 0s;
}
.pricingTable .title{
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  color: #ffffff;
  text-transform: uppercase;
  background: rgb(206, 142, 152);
  padding: 15px 0 0;
  margin: 0 35px 0 0;
  transition: all 0.4s ease 0s;
}
.pricingTable .value{
  display: block;
  font-size: 35px;
  font-weight: 700;
  color: #ffffff;
  background: rgb(206, 142, 152);
  padding: 5px 0 10px;
  margin: 0 35px 0 0;
  transition: all 0.4s ease 0s;
}
.pricingTable:hover .title,
.pricingTable:hover .value{
  color: rgb(206, 142, 152);
}

.pricingTable .content{
  border-left: 1px solid #f2f2f2;
  position: relative;
}
.pricingTable .content:after{
  content: "";
  width: 35px;
  height: 100%;
  background: #f8f8f8;
  box-shadow: 9px 9px 20px #ddd;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  transform: skewY(-45deg) translateY(-18px);
  transition: all 0.4s ease 0s;
}
.pricingTable .content ul{
  padding: 0;
  margin: 0 35px 0 0;
  list-style: none;
  background: #fff;
}
.pricingTable .content ul li{
  display: block;
  font-size: 15px;
  color: #333;
  line-height: 23px;
  padding: 11px 0;
  border-bottom: 1px solid #f2f2f2;
}
.pricingTable .link{
  background: #fff;
  padding: 20px 0;
  margin-right: 35px;
  border-bottom: 1px solid #f2f2f2;
}
.pricingTable .link a{
  display: inline-block;
  padding: 9px 20px;
  font-weight: 700;
  color: rgb(206, 142, 152);
  text-transform: uppercase;
  border: 1px solid rgb(206, 142, 152);
  background: #fff;
  transition: all 0.4s ease 0s;
}
.pricingTable:hover .link a{
  color: rgb(206, 142, 152);
  background: #ffffff;
  border: 1px solid rgb(206, 142, 152);
}
.pricingTable:hover .pricing_heading:after,
.pricingTable:hover .title,
.pricingTable:hover .value{
  background:#ffffff;
}
@media only screen and (max-width: 990px){
  .pricingTable{
      margin-bottom: 35px;
  }
}

.wala{
  font-weight: 500 !important;
  margin-left: 35rem;
}


.zoo{
  width: 100% !important;
  position: relative;
  
}

.nov{
  font-weight: 400;
    font-family: "akagi-pro",sans-serif;
    color: #4D4D4D !important;
    line-height: 2;
}


@media (max-width: 768px) {
  .carousel-small {
    display: block;  /* Show the small screen carousel */
  }
  .carousel-large {
    display: none;   /* Hide the large screen carousel */
  }
}

/* For large screens */
@media (min-width: 769px) {
  .carousel-small {
    display: none;   /* Hide the small screen carousel */
  }
  .carousel-large {
    display: block;  /* Show the large screen carousel */
  }
}

.collapse {
  transition: all 3s ease;
}

.login-box {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 400px;
  padding: 40px;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255);
  box-sizing: border-box;
  box-shadow: 0 15px 25px rgba(0,0,0,.6);
  border-radius: 8%;
}

.login-box h2 {
  margin: 0 0 30px;
  padding: 0;
  color: #000000;
  text-align: center;
}

.login-box .user-box {
  position: relative;
}

.login-box .user-box input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #000000;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #4d4c4c;
  outline: none;
  background: transparent;
}
.login-box .user-box label {
  position: absolute;
  top:0;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  color: rgb(59, 59, 59);
  pointer-events: none;
  transition: .5s;
}

.login-box .user-box input:focus ~ label,
.login-box .user-box input:valid ~ label {
  top: -20px;
  left: 0;
  color: rgb(206, 142, 152);
  font-size: 12px;
}

.login-box form a {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: #03e9f4;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: .5s;
  margin-top: 40px;
  letter-spacing: 4px
}

.back{
  background-color: #8d8b8b;
  height: 1000px;
}

.boxa {
  width: 325px;
  height: 315px;
  border: 2px solid rgb(214, 213, 213);
  border-radius: 15%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  margin: 10px;
  background-color: rgba(0, 0, 0, 0.2);
  margin: 10px;
  max-width: 100%;
}

.hea{
    display: block;
    color:white;
    font-size: 25px;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    text-align: center;
}

.hea.hea-hovered{
  color:rgb(206, 142, 152);
}

.boxa.hovered {
  width: 330px;
  height: 350px;
  background-color: #f7f5f5;

}


.box-button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: rgb(206, 142, 152);
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.box-button:hover {
  background-color: #ffffff;
  color: rgb(206, 142, 152);
}


.App {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}

.ani{
  background:rgb(255, 255, 255);
  padding: 1rem 2rem;
  margin-bottom: 2rem;
}

ani p{
  padding-top: 1rem;
  line-height: 150%;
 
 

}

expand{
  width: 25rem;
}

/* WeekCalendar.css */

.calendar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.day {
  display: flex;
  flex-direction: column;
  min-height: 6rem;
  align-items: center;
  width: calc(100% / 7);
  padding: 10px;
  border: 1px solid rgb(206, 142, 152);
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.day:hover {
  background-color: #eddcdc;
}

.day.selected {
  background-color: #eddcdc;
  color: #302f2f;
}

.day.unavailable {
  background-color: rgb(204, 203, 203); /* or any other color to indicate unavailability */
  cursor: not-allowed;
}


.navigation-buttons button:disabled {
background-color: grey;
cursor: not-allowed;
}


.day-text {
  margin-top: 5px;
  align-items: center;
}

button {
  margin-top: 20px;
  padding: 10px 15px;
  background-color: rgb(206, 142, 152);
  color: white;
  border: none;
  cursor: pointer;
}


.navigation-buttons {
  display: flex;
  justify-content: center ;
  margin-bottom: 10px;
  
}

.navigation-buttons button:first-child {
  margin-right: 30px;
}

.forg{
  color: rgb(206, 142, 152);
}

.forget-form {
  height : 40rem;
}

.change-form{
  height : 40rem;
}


.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: rgb(206, 142, 152);
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px rgb(206, 142, 152);
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px rgb(206, 142, 152);
  }
}


.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid rgb(206, 142, 152); /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}






.sidebar{
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 78px;
  background: rgb(206, 142, 152);
  padding: 6px 14px;
  z-index: 99;
  transition: all 0.5s ease;
}
.sidebar.open{
  width: 250px;
}
.sidebar .logo-details{
  height: 60px;
  display: flex;
  align-items: center;
  position: relative;
}
.sidebar .logo-details .icon{
  opacity: 0;
  transition: all 0.5s ease;
}
.sidebar .logo-details .logo_name{
  color: #fff;
  font-size: 25px;
  font-weight: 600;
  opacity: 0;
  transition: all 0.5s ease;
}
.sidebar.open .logo-details .icon,
.sidebar.open .logo-details .logo_name{
  opacity: 1;
}
.sidebar .logo-details #btn{
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-size: 22px;
  transition: all 0.4s ease;
  font-size: 23px;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s ease;
}
.sidebar.open .logo-details #btn{
  text-align: right;
}
.sidebar i{
  color: #fff;
  height: 60px;
  min-width: 50px;
  font-size: 28px;
  text-align: center;
  line-height: 60px;
}
.sidebar .nav-list{
  margin-top: 20px;
  height: 100%;
}
.sidebar li{
  position: relative;
  margin: 8px 0;
  list-style: none;
}
.sidebar li .tooltip{
  position: absolute;
  top: -20px;
  left: calc(100% + 15px);
  z-index: 3;
  background: #fff;
  box-shadow: 0 5px 10px rgb(206, 142, 152);
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 400;
  opacity: 0;
  white-space: nowrap;
  pointer-events: none;
  transition: 0s;
}
.sidebar li:hover .tooltip{
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
  top: 50%;
  transform: translateY(-50%);
}
.sidebar.open li .tooltip{
  display: none;
}
.sidebar input{
  font-size: 15px;
  color: #FFF;
  font-weight: 400;
  outline: none;
  height: 50px;
  width: 100%;
  width: 50px;
  border: none;
  border-radius: 12px;
  transition: all 0.5s ease;
  background: #1d1b31;
}

.sidebar li a{
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 12px;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  background: rgb(206, 142, 152);
}
.sidebar li a:hover{
  background: #FFF;
}
.sidebar li a .links_name{
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s;
}
.sidebar.open li a .links_name{
  opacity: 1;
  pointer-events: auto;
}
.sidebar li a:hover .links_name,
.sidebar li a:hover i{
  transition: all 0.5s ease;
  color: #11101D;
}
.sidebar li i{
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  border-radius: 12px;
}
.sidebar li.profile{
  position: fixed;
  height: 60px;
  width: 78px;
  left: 0;
  bottom: -8px;
  padding: 10px 14px;
  background: #1d1b31;
  transition: all 0.5s ease;
  overflow: hidden;
}
.sidebar.open li.profile{
  width: 250px;
}
.sidebar li .profile-details{
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
.sidebar li img{
  height: 45px;
  width: 45px;
  object-fit: cover;
  border-radius: 6px;
  margin-right: 10px;
}
.sidebar li.profile .name,
.sidebar li.profile .job{
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  white-space: nowrap;
}
.sidebar li.profile .job{
  font-size: 12px;
}
.sidebar .profile #log_out{
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background: #1d1b31;
  width: 100%;
  height: 60px;
  line-height: 60px;
  border-radius: 0px;
  transition: all 0.5s ease;
}
.sidebar.open .profile #log_out{
  width: 50px;
  background: none;
}
.home-section{
  position: relative;
  min-height: 100vh;
  top: 0;
  left: 78px;
  width: calc(100% - 78px);
  transition: all 0.5s ease;
  z-index: 2;
}
.sidebar.open ~ .home-section{
  left: 250px;
  width: calc(100% - 250px);
}
.home-section .text{
  display: inline-block;
  color: #11101d;
  font-size: 25px;
  font-weight: 500;
  margin: 18px
}
@media (max-width: 420px) {
  .sidebar li .tooltip{
    display: none;
  }
}

.sideImg{
  height: 5vh;
  width: 5vw;
}



.progress, .progress-stacked{
  --bs-progress-bar-bg: rgb(206, 142, 152) !important;
}



.progressblue, .progress-stacked{
  --bs-progress-bar-bg: rgb(206, 142, 152) !important;
  font-weight: 600;
  font-size: 1rem !important;
  height: 2rem !important;
}


.Vcenter {
 
  margin-top: 14vh;
}


.Vcenter3{
 
  margin-top: 3vh;
}


.custom-card{
  min-height: 2rem !important;
  cursor: pointer;
}


.custom-body {
  transition: none !important;
}


.selected {
  box-shadow: 0 0 15px rgb(206, 142, 152);
  transition: box-shadow 0.3s ease; /* Adjust the duration and timing function as needed */
}

.bot.disabled {
  background-color: grey;
  color: white;
  cursor: not-allowed;
}

.arrow-icon {
  font-size: 3rem;
  color: rgb(206, 142, 152);
  cursor: pointer;
}

.marge{
  margin-top:15rem;
}

.staffBut{
  background-color: rgb(206, 142, 152) ;
}


.thinLine{
  
    border: none;
    border-radius: 60px;
    border-top: 15px solid rgb(170, 69, 84) !important; 
}

.radi{
  border-radius: 20px !important;
}

.curse{
  cursor: pointer;
}

.paragraph{
  line-height: 2.5rem;
  font-weight: 400 !important;
}




